<template>
  <v-data-table
    :page="page"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :items-per-page="itemsPerPage"
    :item-class="getItemClass"
    :server-items-length="totalCount"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
  >
    <template v-slot:[`item.startDate`]="{ item }">
      <span
        v-text="item.startDate ? formatDate(item.startDate, 'string') : ''"
      ></span>
    </template>

    <template v-slot:[`item.endDate`]="{ item }">
      <span
        v-text="item.endDate ? formatDate(item.endDate, 'string') : ''"
      ></span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="downloadItem(item)"> mdi-download </v-icon>
      <v-icon small class="mr-2" @click="changeStatus(item)"> mdi-archive </v-icon>
      <v-icon v-if="canDelete" small class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Adı", value: "certificateNumber", sortable: true },

        { text: "Dərs yükü", value: "hours", sortable: false },
        { text: "Tələbə", value: "student", sortable: false },
        { text: "Tədris fənni", value: "subject", sortable: false },
        { text: "Sertifikat növü", value: "certificate", sortable: false },
        { text: "Başlama tarixi", value: "startDate", sortable: false },
        { text: "Bitmə tarixi", value: "endDate", sortable: false },
        { text: "Təsviri", value: "description", sortable: false },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage, sortBy, sortDesc } = value;
        if (
          (sortBy.length > 0 && sortDesc.length > 0) ||
          page > 0 ||
          itemsPerPage >= 10
        ) {
          this.$emit("optionsChanged", {
            page: page,
            itemsPerPage: itemsPerPage,
            sortedBy: sortBy[0],
            sortedDesc: sortDesc[0],
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      let stringDate = new Date(date);
      let formattedDate =
        stringDate.getFullYear() +
        "-" +
        (stringDate.getMonth() + 1) +
        "-" +
        stringDate.getDate();

      const [year, month, day] = formattedDate.split("-");
      return `${day}.${month}.${year}`;
    },

    editItem(item) {
      this.$emit("editItem", item);
    },
    downloadItem(item) {
      this.$emit("downloadItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    changeStatus(item) {
      this.$emit("changeStatus", item);
    },
    getItemClass(item) {
      if (!item.isActive) {
        return "red accent-1";
      }
      return "";
    },
  },
  computed: {
    canDelete() {
      return this.$store.getters.inRole("CertificateDelete");
    },
  },
};
</script>
