<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Sertifikatın redaktəsi" : "Yeni sertifikat" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createCertificateForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-column">
              <strong class="caption gray--text">Sertifikat növü</strong>
              <v-radio-group
                class="py-0 my-0"
                v-model="formData.certificateType"
                row
                hide-details
              >
                <v-radio class="ml-2" label="Gold" :value="1"></v-radio>
                <v-radio class="ml-2" label="Silver" :value="2"></v-radio>
                <v-radio class="ml-2" label="Bronze" :value="3"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Sertifikat nömrəsi"
              filled
              dense
              v-model="formData.certificateNumber"
              :rules="formRules.certificateNumber"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="number"
              label="Dərs yükü (saat)"
              filled
              dense
              v-model="formData.hours"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDateFormatted"
                  label="Başlama tarixi"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  filled
                  required
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.startDate"
                :month-format="getMonthFormat"
                :header-date-format="getHeaderDateFormat"
                :title-date-format="getTitleDateFormat"
                :weekday-format="getWeekdayFormat"
                :first-day-of-week="1"
                @input="startDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDateFormatted"
                  label="Bitmə tarixi"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  filled
                  dense
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="formData.endDate"
                :month-format="getMonthFormat"
                :header-date-format="getHeaderDateFormat"
                :title-date-format="getTitleDateFormat"
                :weekday-format="getWeekdayFormat"
                :first-day-of-week="1"
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" class="">
            <v-autocomplete
              label="Tələbə"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.studentId"
              :items="students"
              required
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="6" class="">
            <v-autocomplete
              label="Tədris fənni"
              filled
              dense
              clearable
              hide-details
              item-text="value"
              item-value="id"
              v-model="formData.subjectId"
              :items="subjects"
              required
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              auto-grow
              rows="1"
              v-model="formData.description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createCertificateForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Yadda saxla" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/services";
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";

export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedCertificate: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  mixins: [datePickerFormatterMixin],
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      formValidation: false,
      subjects: [],
      students: [],
      formData: {
        certificate:"",
        certificateNumber: "",
        description: "",
        hours: "",
        certificateType: "",
        startDate: null,
        endDate: null,
        studentId: null,
        subjectId: null,
      },
      formRules: {
        certificateNumber: [(v) => !!v || "Sertifikatın nömrəsini daxil edin"],
      },
    };
  },
  computed: {
    startDateFormatted() {
      return this.formatDate(this.formData.startDate);
    },
    endDateFormatted() {
      return this.formatDate(this.formData.endDate);
    },
    isEditMode() {
      return (
        this.selectedCertificate &&
        this.selectedCertificate.id &&
        this.selectedCertificate.id > 0
      );
    },
  },
  watch: {
    selectedCertificate: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
    "formData.studentId": {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.fetchSubjects(newVal);
        } else {
          this.subjects = [];
        }
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null      
      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year.padStart(4, '0')}`
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    async fetchSubjects(newVal) {
      this.subjects = await API.fetchAllSubjectsByStudent(newVal);
    },
    resetFormData() {
      this.formData = {
        certificateNumber: "",
        description: "",
        hours: "",
        certificateType: "",
        startDate: null,
        endDate: null,
        studentId: null,
        subjectId: null,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
  async created() {
    this.students = await API.fetchAllStudents();
  },
};
</script>
